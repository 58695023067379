var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { space } from 'styled-system';
import { ButtonLink } from '../../common/components/Button';
import { Flex } from '../../common/components/Flex';
import { textDecoration } from '../../common/theme/system-utilities';
var Wrapper = styled(Flex)(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
Wrapper.defaultProps = __assign(__assign({}, Flex.defaultProps), { justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' });
var MARGIN_X = ['sp_8', 'sp_12'];
var MARGIN_Y = ['sp_4', 'sp_8', null, 'sp_12'];
var Link = styled.a(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), space, textDecoration);
Link.defaultProps = {
    mx: MARGIN_X,
    my: MARGIN_Y,
    textDecoration: 'none',
};
var StyledButtonLink = styled(ButtonLink)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), space);
StyledButtonLink.defaultProps = __assign(__assign({}, ButtonLink.defaultProps), { mx: MARGIN_X, my: MARGIN_Y });
var CtaButtons = function (_a) {
    var items = _a.items;
    return (React.createElement(Wrapper, null, items.map(function (ctaElement, index) {
        return 'image' in ctaElement ? (React.createElement(Link, { key: index, href: ctaElement.link },
            React.createElement("img", { loading: "lazy", src: ctaElement.image.url, alt: ctaElement.image.alt }))) : (React.createElement(StyledButtonLink, { key: index, kind: ctaElement.kind, href: ctaElement.link }, ctaElement.label));
    })));
};
export default CtaButtons;
var templateObject_1, templateObject_2, templateObject_3;
